function ready(fn) {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

function getIdFromParams() {
  const params = location.search.substring(1).split('&');
  return params[0].split('=')[1];
}
function lanchApp() {
  const APP_STORE_URL = 'https://itunes.apple.com/app/id1453263661';
  const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.c_rayon.tym';

  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.match(/iphone|ipad|ipod/)) {
    location.href = APP_STORE_URL;
  } else if (userAgent.match(/android/)) {
    document.location.href = GOOGLE_PLAY_URL;
  } else {
    location.href = APP_STORE_URL;
  }
}

ready(() => {
  lanchApp();
});
